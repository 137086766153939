import React from 'react';
import Header from '../../components/Main/header';
import Footer from '../../components/Main/footer';

const Error = () => {
    return (
        <>
            <Header />

            <div className="content">
                <div className="mid">
                    <div className="errorTitle">
                        <span>404</span>
                    </div>
                    <div className="errorDescription">
                        <p>The page was not found. Please let us know about the problem or go to the main page.</p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Error;