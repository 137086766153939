import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './scenes/Home';
import Privacy from './scenes/Privacy';
import Error from './scenes/404';

const App = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="*" element={<Error />} />
        </Routes>
    );
};

export default App;