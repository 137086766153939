import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';

const Header = () => {

    const [dropdown, setDropdown] = useState(false)

    return (
        <div className="header">
            <div className="mid">
                <div className="headerItems">
                    <div className="headerItem">
                        <NavLink to="/" className="headerLogo">
                            <img src={Logo} alt="Tonsafe" />
                        </NavLink>
                    </div>
                    <div className="headerItem">
                        <div className="headerNavigation">
                            <NavLink to="/privacy" className="headerNavigationItem">Privacy</NavLink>
                            <NavLink to="https://t.me/tonsafe_en" target="_blank" className="headerNavigationItem">Community</NavLink>
                        </div>
                        <div className="headerButton">
                            <div className="headerBurger" onClick={() => setDropdown(!dropdown)}>
                                <div className="headerBurgerLine"></div>
                                <div className="headerBurgerLine"></div>
                                <div className="headerBurgerLine"></div>
                            </div>
                            {dropdown && (
                                <div className="dropdown">
                                    <div className="dropdownContainer">
                                        <div className="dropdownNavigation">
                                            <NavLink to="/privacy" className="dropdownNavigationItem">Privacy</NavLink>
                                            <NavLink to="https://t.me/tonsafe_en" target="_blank" className="dropdownNavigationItem">Community</NavLink>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;