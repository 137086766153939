import React from 'react';
import Header from '../../components/Main/header';
import Footer from '../../components/Main/footer';
import iconIn from '../../assets/images/icons/in.svg';
import iconOut from '../../assets/images/icons/out.svg';
import iconDesign from '../../assets/images/icons/design.svg';
import iconWallet from '../../assets/images/icons/wallet.svg';
// import iconAndroid from '../../assets/images/icons/android.svg';
// import iconIos from '../../assets/images/icons/ios.svg';

const Home = () => {
    return (
        <>
            <Header />

            <div className="offer">
                <div className="mid">
                    <div className="offer">
                        <div className="offerTitle">
                            <span>Your gateway to the world of cryptocurrency</span>
                        </div>
                        <div className="offerDescription">
                            <span>A secure and easy-to-use crypto wallet that allows you to store and send Toncoin and tokens on the TON blockchain.</span>
                        </div>
                        {/* <div className="offerDownload">
                            <a href="#" className="offerDownloadItem">
                                <div className="offerDownloadIcon">
                                    <img src={iconIos} />
                                </div>
                                <div className="offerDownloadStore">Appstore</div>
                            </a>
                            <a href="#" className="offerDownloadItem">
                                <div className="offerDownloadIcon">
                                    <img src={iconAndroid} />
                                </div>
                                <div className="offerDownloadStore">Google Play</div>
                            </a>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="action">
                <div className="mid">
                    <div className="actionItems">
                        <div className="actionItem">
                            <div className="actionIcon">
                                <img src={iconOut} alt="Send" />
                            </div>
                            <div className="actionBody">
                                <div className="actionTitle">
                                    <span>Send</span>
                                </div>
                                <div className="actionDescription">
                                    <span>Send TON and other tokens on The Open Network blockchain in a single tap</span>
                                </div>
                            </div>
                        </div>
                        <div className="actionItem">
                            <div className="actionIcon">
                                <img src={iconIn} alt="Receive" />
                            </div>
                            <div className="actionBody">
                                <div className="actionTitle">
                                    <span>Receive</span>
                                </div>
                                <div className="actionDescription">
                                    <span>Receive TON and other tokens using a QR code in a matter of seconds</span>
                                </div>
                            </div>
                        </div>
                        <div className="actionItem">
                            <div className="actionIcon">
                                <img src={iconDesign} alt="Customization" />
                            </div>
                            <div className="actionBody">
                                <div className="actionTitle">
                                    <span>Customization</span>
                                </div>
                                <div className="actionDescription">
                                    <span>Customize your wallet with NFT's unique Gems collection</span>
                                </div>
                            </div>
                        </div>
                        <div className="actionItem">
                            <div className="actionIcon">
                                <img src={iconWallet} alt="Multi-wallet" />
                            </div>
                            <div className="actionBody">
                                <div className="actionTitle">
                                    <span>Multi-wallet</span>
                                </div>
                                <div className="actionDescription">
                                    <span>Manage multiple cryptocurrency wallets in one mobile app</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Home;