import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <div className="mid">
                <div className="footerCopyright">
                    <span>© All right reserved by Tonsafe Wallet</span>
                </div>
            </div>
        </div>
    );
};

export default Footer;